<template>
    <screening-application
        v-if="Object.keys(currentScreen).length"
        media="print"
        :application_id="$_.get(currentScreen, 'applicant.application.id', {})"
    >
    </screening-application>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ScreeningApplication from "@components/screen/components/ScreeningProcessApplication";

export default {
    name: "application-print",
    components: { ScreeningApplication },
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Screens", ["currentScreen", "currentApplication"]),
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.getCurrentScreen(to.params.id);
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Screens", ["getCurrentScreen"]),
    },
};
</script>

<style scoped>
</style>