var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.currentScreen).length
    ? _c("screening-application", {
        attrs: {
          media: "print",
          application_id: _vm.$_.get(
            _vm.currentScreen,
            "applicant.application.id",
            {}
          )
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }